import React from "react";
import { FileBox } from "./FileBox";
import { CompanyLogo } from "./CompanyLogo";

export class CompanyOffer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            image: props.image,
            files: props.files,
            fileBoxVisible: false,
        };
        this.toggleFilebox = this.toggleFilebox.bind(this);
    }
    toggleFilebox() {
        this.setState({ fileBoxVisible: !this.state.fileBoxVisible });
    }
    render() {
        const fileBox = this.state.fileBoxVisible ? <FileBox files={this.state.files}></FileBox> : <FileBox files={this.state.files} style={{ visibility: "hidden" }}></FileBox>;
        return (
            <div className="f-container f-third f-col">
                <CompanyLogo image={this.state.image} onClick={this.toggleFilebox}></CompanyLogo>
                {fileBox}
            </div>
        );
    }

}