import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

export class ContactForm extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            submitAction: props.submitAction ? props.submitAction : "",
            validateHandler: props.validateHandler ? props.validateHandler : (e: any) => { return true; },
            sitekey: props.sitekey,
            recaptchaRef: React.createRef()
        };

        this.validateSubmit = this.validateSubmit.bind(this);
    }
    validateSubmit(e: any) {
        let capRef = this.state.recaptchaRef.current;
        let captchaVal = capRef.getValue();
        if (captchaVal === "") {
            this.setState({ captchaMessage: "Najpierw wypełnij reCAPTCHA" });
            e.preventDefault();
        }

        return this.state.validateHandler(e);
    }
    render() {
        const errorColor = "#ab0a0a";
        const okColor = "#0aab0a";
        const captchaMessage = this.state.captchaMessage ? <p style={{ color: errorColor }}>{this.state.captchaMessage}</p> : <></>;
        const recaptcha = this.state.sitekey ?
            <>
                <ReCAPTCHA style={{ marginTop: "2em" }} ref={this.state.recaptchaRef} sitekey={this.state.sitekey}></ReCAPTCHA>
                {captchaMessage}
            </>
            :
            <></>;

        let message = <></>;
        if (window.location.href.includes("?success")) {
            message = <p style={{ color: okColor, marginTop: "1em" }}>Poprawnie wysłano wiadomość</p>
        } else if (window.location.href.includes("?error")) {
            message = <p style={{ color: errorColor, marginTop: "1em" }}>Nie udało się wysłać wiadomości, spróbuj ponownie...</p>
        }
        return (
            <form id="contact-form" className="f-container f-col" onSubmit={this.validateSubmit} action={this.state.submitAction}>
                {message}
                <label htmlFor="email">
                    <h3 className="clr-dark">Adres email:</h3>
                </label>
                <input required type="email" id="email" name="email"
                    placeholder="np. jan.kowalski@poczta.pl..." />

                <label htmlFor="subject">
                    <h3 className="clr-dark">Temat:</h3>
                </label>
                <input required type="text" id="subject" name="subject" placeholder="temat..." />

                <label htmlFor="message">
                    <h3 className="clr-dark">Wiadomość:</h3>
                </label>
                <textarea id="message" name="message" placeholder="..."></textarea>

                {recaptcha}

                <input id="submit-btn" className="btn clickable centered round blue big" type="submit"
                    value="Submit" />
            </form>

        );
    }
}
