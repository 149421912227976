export function Hero(props: any) {
    return (
        <div id="hero" className="f-container f-a-center f-j-center f-wrap">
            <h1 id="hero-title" className="centered">{props.title}</h1>
            <div className="f-break"></div>
            <h4>{props.subtitle}</h4>
            <div className="f-break"></div>
            {props.buttons}
        </div>
    )
}
