
export function ContactAddress(props: any) {
    const iconClasses = "fa icon big background round-corners centered f-a-center " + props.icon;
    const address = props.href ? <a href={props.href}>{props.address}</a> : props.address;
    return (
        <div className="f-third f-container f-col f-space">
            <i className={iconClasses}></i>
            <h4 className="f-a-center">
                {address}
            </h4>
        </div>
    );

}