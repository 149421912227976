import React from "react";

const MIN_WIDTH = 891;

export class MenuItem {
    href: string;
    value: string;
}

export class NavBar extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            logo: props.logo,
            menu: props.menu,
            menuVisible: window.innerWidth >= MIN_WIDTH,
            width: 0,
            height: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    switchMenu(e: any) {
        const visible = !this.state.menuVisible;
        this.setState({ menuVisible: visible });
    }

    render() {
        let menuItems = [];
        let i = 0;
        for (let item of this.state.menu) {
            menuItems.push(
                (
                    <a key={i.toString()} className="f-menu-item" href={item.href} > {item.value}</a >
                )
            );
            i++;
        }

        const hamburgerVisible = this.state.width < MIN_WIDTH;
        const hamburger = hamburgerVisible ?
            <i id="hamburger" className="clickable fa clr-dark icon medium round fa-bars f-a-center" onClick={(e) => { this.switchMenu(e) }}></i>
            :
            <></>;


        const menu = this.state.menuVisible ?
            <div id="menu" className="f-container f-a-center">
                {menuItems}
            </div>
            :
            <></>;

        const png = this.state.logo + '.png';

        return (
            <div id="navbar" className='sticky xmax f-space f-container f-wrap f-a-center' >
                <a href="/" className='f-a-center'>
                    <picture>
                        <source srcSet={this.state.logo + '.webp'} type="image/webp" />
                        <source srcSet={png} type="image/png" />
                        <img id="logo" alt="logo" src={png} />
                    </picture>
                </a>
                {hamburger}
                <div id="menu-break"></div>
                <div id="menu-filler" className="m-1"></div>
                {menu}

            </div>
        );
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        let state: any = { width: window.innerWidth, height: window.innerHeight };
        if (window.innerWidth > MIN_WIDTH && this.state.width <= MIN_WIDTH) {
            state.menuVisible = true;
        }
        if (window.innerWidth < MIN_WIDTH && this.state.width >= MIN_WIDTH) {
            state.menuVisible = false;
        }
        this.setState(state);
    }
}
