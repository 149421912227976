export function CompaniesDisplay(props: any) {
    let rows = [];
    let i = 0;

    for (let row of props.items ? props.items : []) {
        rows.push(
            <div key={i.toString()} id="companies-wrp" className="f-container f-row f-nowrap xmax companies-row">
                {row}
            </div>
        );
        i++;
    }

    return (
        <div>
            <h2 className="centered clr-dark">{props.title}</h2>
            <p className="centered bigger">{props.text}</p>
            {rows}
        </div>
    );

}