import { DescriptionTab } from "./DescriptionTab";

export function Details(props: any) {
    let items = [];
    for (let item of props.items) {
        items.push(
            <DescriptionTab key={item.title} icon={item.icon} title={item.title} text={item.text}></DescriptionTab>
        );
    }
    const title = props.title ? <h2 className="centered clr-dark">{props.title}</h2> : <></>;
    const text = props.text ? <p className="centered bigger">{props.text}</p> : <></>;
    return (
        <div id="details-wrp">
            {title}
            {text}

            <div id="info-wrp" className="f-container f-row f-nowrap">
                {items}
            </div>
        </div>
    );
}
