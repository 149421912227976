import { FileLink } from "./FileLink";

export function FileBox(props: any) {
    let files = [];
    for (let file of props.files) {
        files.push(
            <li key={file.title}>
                <FileLink title={file.title} href={file.href}></FileLink>
            </li>
        );
    }
    return (
        <div className="f-third files-box" style={props.style ? props.style : {}}>
            <ul>
                {files}
            </ul>
        </div>
    );
}