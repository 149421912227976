export function CompanyLogo(props: any) {
    return (
        <div className="logo-box" id="toshiba-logo" onClick={props.onClick}>
            <picture>
                <source srcSet={props.image + '.webp'} type="image/webp" />
                <source srcSet={props.image + '.png'} type="image/png" />
                <img className="xmax ymax clickable" alt="Toshiba" src={props.image + '.png'} />
            </picture>
        </div>
    );
}
