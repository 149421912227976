import React from 'react';
import { MenuItem, NavBar } from "./components/NavBar";
import { Button } from "./components/Button";
import { Hero } from "./components/Hero";
import { Footer } from "./components/Footer";
import { CompanyOffer } from "./components/CompanyOffer";
import { Details } from "./components/Details";
import { CloudSeparator } from "./components/CloudSeparator";
import { ContactView } from './components/ContactView';
import { CompaniesDisplay } from './components/CompaniesDisplay';


function hasWebpSupport() {
  const elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } else {
    return false;
  }
}

class App extends React.Component<any, any> {
  render() {
    const menuItems: Array<MenuItem> = [
      { href: "#o-nas", value: "O nas" },
      { href: "#oferta", value: "Oferta" },
      { href: "#kontakt", value: "Kontakt" },
      { href: "#realizacje", value: "Realizacje" },
    ];

    const company = "SK-Klima";
    const postalCode = "02-681";
    const city = "Warszawa";
    const street = "Al. Wyścigowa 14";
    const email = "info@sk-klima.pl";
    const phone = "+48 601 280 706";
    const sitekey = "6LecbfIeAAAAAAJK8OqQB1why-Vl7S9RLmr5fKCL";

    const offers = [[
      <CompanyOffer image="static/toshiba"
        files={[
          { href: "catalogs/katalog_toshiba_HAORI_Wienkra.pdf", title: "Katalog Toshiba HAORI" },
          { href: "catalogs/katalog_toshiba_multi-split_2021.pdf", title: "Katalog Toshiba Multi-Split" },
          { href: "catalogs/katalog_toshiba_2021.pdf", title: "Katalog Toshiba" },
          { href: "catalogs/katalog_toshiba_seiya_2gen.pdf", title: "Katalog Toshiba Seiya 2" },
          { href: "catalogs/cennik_toshiba_2024.pdf", title: "Cennik Toshiba 2024" },
        ]} />,
      <CompanyOffer image="static/mhi"
        files={[
          { href: "catalogs/katalog_MHI-RAC_2021.pdf", title: "Katalog RAC Mitsubishi Heavy Industries" },
          { href: "catalogs/katalog_MHI_Multi-Split_2021.pdf", title: "Katalog Multi-Split Mitsubishi Heavy Industries" },
          { href: "catalogs/cennik_mitsubishi_2024.pdf", title: "Cennik Mitsubishi Heavy Industries 2024" },
        ]} />,
      <CompanyOffer image="static/gree"
        files={[
          { href: "catalogs/katalog_gree_2023.pdf", title: "Katalog Gree" },
          { href: "catalogs/cennik_gree_2024-2.pdf", title: "Cennik Gree 2024" },
        ]} />
    ]]

    const heroButtons = [
      <Button key="oferta" href="#oferta" value="Oferta"></Button>,
      <Button key="kontakt" href="#kontakt" value="Kontakt"></Button>,
    ];

    const imageExtension = hasWebpSupport() ? ".webp" : ".jpg";

    const wrapperBackground = './static/bg' + imageExtension;
    const contactImage = "./static/sky" + imageExtension;

    return (
      <>
        <div id="wrapper" className="f-container f-col f-space" style={{ backgroundImage: "url(" + wrapperBackground + ")" }}>
          <NavBar
            logo="./static/logo"
            menu={menuItems}
          ></NavBar>
          <div> </div> {/* Nie usuwać! */}
          <Hero
            title="Klimatyzacja dla domu, biura i przemysłu"
            subtitle="Sprawdź naszą ofertę"
            buttons={heroButtons}
          ></Hero>
          <CloudSeparator></CloudSeparator>
        </div >
        <div id="o-nas"></div>
        <div id="content-wrp" className="xmax">
          <div id="content" className="f-container f-col centered">
            <Details
              items={[
                {
                  title: "Lata doświadczenia",
                  icon: "fa-info-circle",
                  text: `
                    Nasza firma działa na polskim rynku już od ponad 20 lat zapewniając specjalistyczne rozwiązania
                    z branży klimatyzacyjnej i wentylacyjnej w sektorze biurowym, przemysłowym jak i w domach czy
                    mieszkaniach.`
                },
                {
                  title: "Doradztwo",
                  icon: "fa-user-circle",
                  text: `
                    Zawsze proponujemy klientom optymalne rozwiązania z punktu widzenia ceny i uzyskanego efektu. Do
                    każdego rozwiązania podchodzimy indywidualnie i z największą dokładnością aby zapewnić zgodność
                    z oczekiwaniami klienta.`
                },
                {
                  title: "Urządzenia",
                  icon: "fa-cogs",
                  text: `
                    Marki, z którymi współpracujemy to światowej klasy liderzy, których rozwiązania są najwyższej
                    jakości. Urządzenia zapewniają wydajną, bezawaryjną pracę na wiele lat nie ograniczając przy tym
                    wygody stosowania.`
                }
              ]}
            >
            </Details>
          </div>
          <div id="oferta"></div>
          <CompaniesDisplay items={offers}
            title="Urządzenia"
            text="Aby zapoznać się z katalogami i cennikami wybierz logo jednego z polecanych przez nas producentów."
          />


          <div id="kontakt"></div>
          <div id="kontakt?success"></div>
          <ContactView address={<>{postalCode}, {city}<br />{street}</>}
            phone={phone}
            email={email}
            sitekey={sitekey}
            backgroundImage={"url(" + contactImage + ")"}
            submitAction="/cgi-bin/form.py"
          ></ContactView>
          <Footer text={company}></Footer>
        </div>
      </>
    );
  }
}

export default App;
