import { ContactTriplet } from "./ContactTriplet";
import { ContactForm } from "./ContactForm";

export function ContactView(props: any) {
    let style = props.backgroundImage ? { backgroundImage: props.backgroundImage } : {}
    return (
        <div id="contact-wrp" className="f-container f-col f-nowrap">
            <div id="contact" className="f-container f-col f-nowrap f-a-center clr-dark" style={style}>
                <h2 className="clr-dark centered">Kontakt</h2>
                <ContactTriplet address={props.address} phone={props.phone} email={props.email}></ContactTriplet>
                <ContactForm validateHandler={props.validateHandler} submitAction={props.submitAction} sitekey={props.sitekey}></ContactForm>
            </div>
        </div>
    );

}