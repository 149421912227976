import { ContactAddress } from "./ContactAddress";

export function ContactTriplet(props: any) {
    return (
        <div id="contact-info" className="f-container f-row f-nowrap">
            <ContactAddress icon="fa-envelope" address={props.email} href={"mailto:" + props.email} />
            <ContactAddress icon="fa-map-marker" address={props.address} />
            <ContactAddress icon="fa-phone" address={props.phone} />
        </div>
    );
}