export function CloudSeparator(props: any) {
    return (
        <svg className="svg-white-bg xmax f-end" preserveAspectRatio="none" width="1000" height="100" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path className="svg-blue-bg"
                    d="M0 3c19.077 11.646 33.649 20.14 43.716 25.482 40.874 21.692 85.618 32.538 134.233 32.538 99.863 0 100.139-45.02 161.859-45.02 61.721 0 169.387 66.539 321.781 66.539 109.03 0 221.834-26.18 338.411-78.539v96h-999.324l-.676-97z"
                    fillOpacity=".6">
                </path>
                <path className="svg-twhite-bg"
                    d="M0 0c51.673 34.545 110.797 51.818 177.372 51.818 99.863 0 100.372-39.818 162.093-39.818 61.721 0 131.668 58.635 323.21 55.759 127.695-1.917 240.136-24.504 337.325-67.759v100h-999.324l-.676-100z"
                    fillOpacity=".8">
                </path>
                <path className="svg-white-bg"
                    d="M0 7c50.985 41.747 109.765 62.62 176.34 62.62 99.863 0 99.654-49.62 161.375-49.62 61.721 0 158.15 69.396 324.285 69.396 110.757 0 223.424-26.465 338-79.396v90h-1000v-93z">
                </path>
            </g>
        </svg>
    );
}
