export function DescriptionTab(props: any) {
    const iconClasses = "fa icon big background round-corners f-a-center " + props.icon;
    return (
        <div className="f-third f-container f-col">
            <i className={iconClasses}></i>
            <h3 className="f-a-center clr-dark">{props.title}</h3>
            <p className="f-a-center">
                {props.text}
            </p>
        </div >
    )
}
